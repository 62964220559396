import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import queryString from 'query-string';


// const products = [
//   { name: 'Product 1', desc: 'A nice thing', price: '$9.99' },
//   { name: 'Product 2', desc: 'Another thing', price: '$3.45' },
//   { name: 'Product 3', desc: 'Something else', price: '$6.51' },
//   { name: 'Product 4', desc: 'Best thing of all', price: '$14.11' },
//   { name: 'Shipping', desc: '', price: 'Free' },
// ];

const products = [
  {
    id: 1,
    name: 'Primary Color',
    price: '100$',
    desc:
      'Primary Colored Bounce House!',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
  {
    id : 2,
    name: 'Pink and Purple',
    price: '100$',
    desc:
      'Pink and Purple Bounce House!',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
   {
    id:3,
    name: 'Combo Jumpers',
    price: '200$',
    desc:
      'Jumper With A slide!',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
  {
    id:4,
    name: 'WaterSlide',
    price: '250$',
    desc:
      'For those hot Summer Days!',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
];

const payments = [
  { name: 'Card type', detail: 'Visa' },
  { name: 'Card holder', detail: 'Mr John Smith' },
  { name: 'Card number', detail: 'xxxx-xxxx-xxxx-1234' },
  { name: 'Expiry date', detail: '04/2024' },
];

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

export default function Review(props) {
  const classes = useStyles();

  const value=queryString.parse(window.location.search);
  const productId=value.productId;
  console.log(productId);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
          <ListItem className={classes.listItem} key={products[productId - 1].name}>
            <ListItemText primary={products[productId - 1].name} secondary={products[productId - 1].desc} />
            <Typography variant="body2">{products[productId - 1].price}</Typography>
          </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" className={classes.total}>
            {products[productId - 1].price}
          </Typography>
        </ListItem>
      </List>
      <br />
      <hr />
      <br />
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Info
          </Typography>
          <Typography gutterBottom><b>Name:</b> {props.firstName} {props.lastName}</Typography>
          <Typography gutterBottom><b>Phone Number:</b> {props.phoneNumber}</Typography>
          <Typography gutterBottom><b>Email:</b> {props.email}</Typography>
          
          <br />
          <Typography variant="h6" gutterBottom className={classes.title}>
            Shipping
          </Typography>
          <Typography gutterBottom><b> Address: </b> {props.address}</Typography>
         {props.address2 && <Typography gutterBottom><b> Address2: </b> {props.address2}</Typography>}
          <Typography gutterBottom><b>City:</b> {props.city} {props.state} {props.zipcode}</Typography>
          <Typography gutterBottom><b>State:</b> {props.state} </Typography>
          <Typography gutterBottom><b>Zipcode:</b> {props.zipcode}</Typography>

          <Typography gutterBottom>Reservation Date: {format(props.date, 'eeee LLLL Io, yyyy')} </Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom className={classes.title}>
            Payment details
          </Typography>
          <Grid container>
              <React.Fragment key={props.creditCardName}>
                <Grid item xs={6}>
                  <Typography gutterBottom>Name: {props.creditCardName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{props.creditCardNumber}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>Exp: {props.creditCardExpDate}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom> CVV: {props.creditCardCvv}</Typography>
                </Grid>
              </React.Fragment>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}