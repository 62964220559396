import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import DeliveryDate from './DeliveryDate';
import swal from 'sweetalert'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        BouncyHouse.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Address', 'Reservation Date', 'Payment details', 'Review your order'];


export default function Checkout() {



  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);


  // user Information
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const [address, setAddress] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('')
  const [useInfoForCC, setUseInfoForCC] = useState(false);


  //Reservation Info
  const [date, setDate] = useState(new Date());


  // CC Info

  const [creditCardName, setCreditCardName] = useState('');
  const [creditCardNumber, setCreditCardNumber] = useState(''); 
  const [creditCardExpDate, setCreditCardExpDate] = useState('');
  const [creditCardCvv, setCreditCardCvv] = useState('');


  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AddressForm 
                  firstName = {firstName}
                  setFirstName = {setFirstName}
                  lastName = {lastName}
                  setLastName = {setLastName}
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}
                  email={email}
                  setEmail={setEmail}
                  address = {address}
                  setAddress = {setAddress}
                  address2 = {address2}
                  setAddress2 = {setAddress2}
                  city = {city}
                  setCity = {setCity}
                  state = {state}
                  setState = {setState}
                  zipcode = {zipcode}
                  setZipcode = {setZipcode}
                  useInfoForCC = {useInfoForCC}
                  setUseInfoForCC = {setUseInfoForCC}
                />;
      case 1: 
        return <DeliveryDate date={date} setDate={setDate}/>;
      case 2:
        return <PaymentForm 
            creditCardName={creditCardName}
            setCreditCardName={setCreditCardName}
            creditCardNumber={creditCardNumber}
            setCreditCardNumber={setCreditCardNumber}
            creditCardExpDate={creditCardExpDate}
            setCreditCardExpDate={setCreditCardExpDate}
            creditCardCvv={creditCardCvv}
            setCreditCardCvv={setCreditCardCvv}
        />;
      case 3:
        return <Review 
                  firstName = {firstName}
                  lastName = {lastName}
                  phoneNumber={phoneNumber}
                  email={email}
                  address = {address}
                  address2 = {address2}
                  city = {city}
                  state = {state}
                  zipcode = {zipcode}
                  useInfoForCC = {useInfoForCC}
                  date={date}
                  creditCardName={creditCardName}
                  creditCardNumber={creditCardNumber}
                  creditCardExpDate={creditCardExpDate}
                  creditCardCvv={creditCardCvv}
        />;
      default:
        throw new Error('Unknown step');
    }
}


  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };



  return (
    <React.Fragment>

      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Bouncy House
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
               {/* <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #{Math.floor(Math.random() * 100 + 1)}. We have emailed your order confirmation, and will
                  call you the day before to Confirm. Thank You!
                </Typography>*/}
                 
                 <Typography variant="h5" gutterBottom>
                  This Order is not Real!
                </Typography>
                <Typography variant="subtitle1">
                  Thank you for getting this far on the website, but we are currently in the testing phase. Please Try again later.
                  Sorry for the inconvenience!
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}