import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import GitHubIcon from '@material-ui/icons/GitHub';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Footer from './Footer';
import swal from 'sweetalert'


const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));

const sections = [
  { title: '', url: '/' },
 
];

const mainFeaturedPost = {
  title: 'The Bouncy House has arrived!',
  description:
    "The Best Baby Sitter a party can have!",
  image: 'https://source.unsplash.com/random',
  imgText: '',
  linkText: '',
};

const featuredPosts = [
  {
    id: 1,
    title: 'Primary Color',
    date: '100$',
    description:
      'Primary Colored Bounce House!',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
  {
    id : 2,
    title: 'Pink and Purple',
    date: '100$',
    description:
      'Pink and Purple Bounce House!',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
   {
    id:3,
    title: 'Combo Jumpers',
    date: '200$',
    description:
      'Jumper With A slide!',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
  {
    id:4,
    title: 'WaterSlide',
    date: '250$',
    description:
      'For those hot Summer Days!',
    image: 'https://source.unsplash.com/random',
    imageText: 'Image Text',
  },
];



export default function Blog(props) {
  const classes = useStyles();
  swal({title: "Sorry", text: "BouncyHouse.com is not finished and is coming soon", icon: "warning"})

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header {...props} title="Bouncy House" sections={sections} />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost {...props} key={post.title} post={post} />
            ))}
          </Grid>
        </main>
      </Container>
      <Footer title="Bouncy House" description="" />
    </React.Fragment>
  );
}