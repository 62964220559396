import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export default function PaymentForm(props) {
  console.log(props)
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Payment method
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField 
          required 
          id="cardName" 
          label="Name on card" 
          value={props.creditCardName}
          onChange={(e) => props.setCreditCardName(e.target.value)}
          fullWidth 
          autoComplete="cc-name" />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label="Card number"
            value={props.creditCardNumber}
            onChange={(e) => props.setCreditCardNumber(e.target.value)}
            fullWidth
            autoComplete="cc-number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField 
            required 
            id="expDate" 
            label="Expiry date" 
            value={props.creditCardExpDate}
            onChange={(e) => props.setCreditCardExpDate(e.target.value)}
            fullWidth 
            autoComplete="cc-exp"
        />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
             value={props.creditCardCvv}
            onChange={(e) => props.setCreditCardCvv(e.target.value)}
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
          />
        </Grid>
        <Grid item xs={12}>
          {/*<FormControlLabel
            control={<Checkbox color="secondary" name="saveCard" value="yes" />}
            label="Remember credit card details for next time"
          />*/}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}