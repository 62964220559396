import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DatePicker from '@material-ui/lab/DatePicker';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import DesktopDatePicker from '@material-ui/lab/DesktopDatePicker';

export default function ResponsiveDatePickers(props) {
  console.log(props.date)
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div style={{ width: 300 }}>
        <DesktopDatePicker
          label="Reservation"
          value={props.date}
          minDate={Date.now()}
          onChange={(newValue) => {
            props.setDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} margin="normal" />}
        />
      </div>
    </LocalizationProvider>
  );
}
