import logo from './logo.svg';
import './App.css';
import Blog from './Components/Home/Blog'
import Checkout from './Components/Payment/Checkout';
import SignIn from './Components/Login/SignIn'
import SignUp from './Components/Login/SignUp'
import swal from 'sweetalert'
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


// function ComingSoon() {
//   return (

//     <div> Bouncy House.com is Coming soon.. </div>


//   )
// }

export default function App() {
  swal({title: "Sorry", text: "BouncyHouse.com is not finished and is coming soon", icon: "warning"})

  return (
    <Router>

        <Switch>
          <Route path="/payment">
            <Checkout />
          </Route>
          <Route path="/login" >
            <SignIn />
          </Route>
           <Route path="/signUp" >
            <SignUp />
          </Route>
          <Route path="/" render={(props) => <Blog {...props} />} />

        </Switch>
      
    </Router>
  );
}

