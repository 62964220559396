import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


export default function AddressForm(props) {


  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            value={props.firstName}
            onChange={(e) => props.setFirstName(e.target.value)}
            fullWidth
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            value={props.lastName}
            onChange={(e) => props.setLastName(e.target.value)}
            fullWidth
            autoComplete="family-name"
          />
        </Grid>

         <Grid item xs={12} sm={6}>
          <TextField
            required
            id="phone"
            name="phone"
            label="Phone Number"
            value={props.phoneNumber}
            onChange={(e) => props.setPhoneNumber(e.target.value)}
            fullWidth
            autoComplete="phone phone-number phoneNumber"
          />
          <small> We will only use this to contact you about the rental</small>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="email"
            value={props.email}
            onChange={(e) => props.setEmail(e.target.value)}
            fullWidth
            autoComplete="email"
          />
          <small> We will only use this to contact you about the rental</small>
        </Grid>


        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            value={props.address}
            onChange={(e) => props.setAddress(e.target.value)}
            fullWidth
            autoComplete="shipping address-line1"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            value={props.address2}
            onChange={(e) => props.setAddress2(e.target.value)}
            fullWidth
            autoComplete="shipping address-line2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            value={props.city}
            onChange={(e) => props.setCity(e.target.value)}
            fullWidth
            autoComplete="shipping address-level2"
          />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField value={props.state} onChange={(e) => props.setState(e.target.value)} id="state" name="state" label="State" fullWidth />
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            value={props.zipcode}
            onChange={(e) => props.setZipcode(e.target.value)}
            fullWidth
            autoComplete="shipping postal-code"
          />
        </Grid>




        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox color="secondary" name="saveAddress" onChange={(e) => props.setUseInfoForCC(e.target.checked)} value={props.useInfoForCC} />}
            label="Use this address for payment details"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}